import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Dial from "./dial"
import HeaderBox from './headerBox'
import DateJs from 'datejs'
import Chart from './chart'
import _ from 'lodash'
import SkullLoad from './skullLoad'

export function CurrentTrades(props) {
  const [trades, setTrades] = useState([]);
  const [ethTrades, setEthTrades] = useState([]);
  const [ltcTrades, setLtcTrades] = useState([]);
  const [btcTrades, setBtcTrades] = useState([]);
  const [altCoinTrades, setaltCoinTrades] = useState([]);
  const [valueData, setValueData] = useState([]);
  const [cleanedData, setcleanedData] = useState([]);
  const [loading, setloading] = useState(true);


  const [ethFees, setethFees] = useState(0);
  const [ltcFees, setltcFees] = useState(0);
  const [btcFees, setbtcFees] = useState(0);
  const [altCoinFees, setaltCoinFees] = useState(0);

  const [stats, setStats] = useState({ 
    ethValue: 0, 
    ethDeposit: 0,
    ltcValue: 0,
    ltcDeposit: 0,
    btcValue: 0,
    btcDeposit: 0
  });

  const [totalValue, setTotalValue] =useState(0)
  const [totalDeposit, setTotalDeposit] = useState(0)
  const [totalGainLossPerc, setTotalGainLossPerc] = useState(1);

  const initialMount = useRef(true)
  useEffect(() => {

    if (initialMount.current) {
      initialMount.current = false;
    } else {
      let cleanedData = []
      valueData.forEach(dp => {
        cleanedData.push({
          date: dp.date,
          BTC: dp.data.btcValue,
          ETH: dp.data.ethValue,
          LTC: dp.data.ltcValue,
          XLM: dp.data.altCoinValue,
        })
      })
      setcleanedData(cleanedData)
    }
  }, [valueData])
    

  useEffect(() => {
    let date = new Date();
    let days = 7;
    let last = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
    let valueData = [];
    
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/getDateRangeStats', {date: last}).then(res => {
      let data = [];
      res.data.forEach(item => {
        const dateInMillis = item.date._seconds * 1000
        var date = new Date(dateInMillis).toDateString()
        data.push({
          date,
          data: item
        })
      })
      valueData = _.uniqBy(data, 'date')
      setValueData(valueData)
      setTimeout(()=> {setloading(false)}, 500)
    })
  }, [])

  useEffect(() => {
    axios({
      url: 'https://us-central1-bitbot-fdaae.cloudfunctions.net/getStats',
      method: 'GET',
    }).then(res => {
      setStats(res.data[0])
      setTotalDeposit(parseFloat(res.data[0].btcDeposit + res.data[0].ethDeposit + res.data[0].ltcDeposit + res.data[0].altCoinDeposit))
      setTotalValue(parseFloat(res.data[0].btcValue + res.data[0].ltcValue + res.data[0].ethValue + res.data[0].altCoinValue))
    })
  }, [])

  useEffect(() => {
    axios({
      url: 'https://us-central1-bitbot-fdaae.cloudfunctions.net/getTrades?live=true',
      method: 'GET',
    }).then(res => {
      setTrades(res.data)

      let btcTrades = res.data.filter(obj => {
        return obj.product_id === 'BTC-USD'
      })
      setBtcTrades(btcTrades)

      let ethTrades = res.data.filter(obj => {
        return obj.product_id === 'ETH-USD'
      })
      setEthTrades(ethTrades)

      let ltcTrades = res.data.filter(obj => {
        return obj.product_id === 'LTC-USD'
      })
      setLtcTrades(ltcTrades)

      let altCoinTrades = res.data.filter(obj => {
        return obj.product_id === 'XLM-USD'
      })
      setaltCoinTrades(altCoinTrades)

    })
  },[])


  useEffect(() => {
    let fees = 0
    btcTrades.forEach(trade => {
      fees = parseFloat(fees) + parseFloat(trade.fill_fees)
    })
    setbtcFees(parseFloat(fees).toFixed(2))
  }, [btcTrades])

  useEffect(() => {
    let fees = 0
    ltcTrades.forEach(trade => {
      fees = parseFloat(fees) + parseFloat(trade.fill_fees)
    })
    setltcFees(parseFloat(fees).toFixed(2))
  }, [ltcTrades])

  useEffect(() => {
    let fees = 0
    ethTrades.forEach(trade => {
      fees = parseFloat(fees) + parseFloat(trade.fill_fees)
    })
    setethFees(parseFloat(fees).toFixed(2))
  }, [ethTrades])

  useEffect(() => {
    let fees = 0
    altCoinTrades.forEach(trade => {
      fees = parseFloat(fees) + parseFloat(trade.fill_fees)
    })
    setaltCoinFees(parseFloat(fees).toFixed(2))
  }, [altCoinTrades])

  useEffect(() => {
    setTotalGainLossPerc(calcGainLossPerc(totalValue, totalDeposit))
  }, [totalValue, totalDeposit])

  let calcGainLossPerc = (value, deposit) => {
    if(value !== 0 && deposit !== 0) {
      value = parseFloat(value).toFixed(2)
      deposit = parseFloat(deposit).toFixed(2)
      return (((value - deposit) / deposit) * 100).toFixed(0)
    } else {
      return 0
    }
  }

  return (
    <>
    <div className={loading ? "overlay show" : "overlay hide"}>
        <SkullLoad maxwidth="800px"/>
        <div className="lds-facebook"><div></div><div></div><div></div></div>
        <h1 className="skum center">Getting Datazzz...</h1>
    </div>
      <div className="mobileheader" style={{position: `relative`, margin: `0 auto`, display: `flex`, justifyContent: `center`}}>
        <HeaderBox 
          deposit={totalDeposit.toLocaleString()}
          value={totalValue.toLocaleString()}
          gainloss={totalDeposit - totalValue}
          gainlossperc={totalGainLossPerc}
        />
      </div>
      <div className="chartcontainer">
        <div style={{ maxWidth: `800px`, minWidth: '50%' }}>
          <Chart data={cleanedData} />
        </div>
      </div>
      <div className="dialHolder">
        <Dial color="#FF00C8" 
          asset="LTC"
          value={stats.ltcValue}
          gainloss={parseInt(stats.ltcValue) - parseInt(stats.ltcDeposit)}
          gainlossperc={calcGainLossPerc(stats.ltcValue, stats.ltcDeposit)}
          startingvalue={parseFloat(stats.ltcDeposit).toLocaleString()}
          fees={parseFloat(ltcFees).toFixed(2)}
          tradeinfo={ltcTrades.length > 0 ? ltcTrades[0] : 'NA'}
          lasttrade={ltcTrades.length > 0 ? new Date(ltcTrades[0].created_at).toString('M/d/yyyy hh:mm tt') : 'NA'}
          lastsignal={ltcTrades.length > 0 && ltcTrades[0].side ? `${ltcTrades[0].side.toUpperCase()} @ $${parseInt(ltcTrades[0].signal_price).toLocaleString()}` : 'NA'}
        />
        <Dial color="#0DFF15" 
          asset="BTC"
          value={stats.btcValue} 
          gainloss={parseInt(stats.btcValue) - parseInt(stats.btcDeposit)} 
          gainlossperc={calcGainLossPerc(stats.btcValue, stats.btcDeposit)}
          startingvalue= {parseFloat(stats.btcDeposit).toLocaleString()}
          fees={parseFloat(btcFees).toFixed(2)}
          tradeinfo={btcTrades.length > 0 ? btcTrades[0] : 'NA'}
          lasttrade={btcTrades.length > 0 ? new Date(btcTrades[0].created_at).toString('M/d/yyyy hh:mm tt') : 'NA'}
          lastsignal={btcTrades.length > 0 && btcTrades[0].side ? `${btcTrades[0].side.toUpperCase()} @ $${parseInt(btcTrades[0].signal_price).toLocaleString()}` : 'NA'}

        />
        <Dial color="#00FFE5" 
          asset="ETH"
          value={stats.ethValue}
          gainloss={parseInt(stats.ethValue) - parseInt(stats.ethDeposit)}
          gainlossperc={calcGainLossPerc(stats.ethValue, stats.ethDeposit)}
          startingvalue={parseFloat(stats.ethDeposit).toLocaleString()}
          fees={parseFloat(ethFees).toFixed(2)}
          tradeinfo={ethTrades.length > 0 ? ethTrades[0] : 'NA'}
          lasttrade={ethTrades.length > 0 ? new Date(ethTrades[0].created_at).toString('M/d/yyyy hh:mm tt') : 'NA'}
          lastsignal={ethTrades.length > 0 && ethTrades[0].side ? `${ethTrades[0].side.toUpperCase()} @ $${parseInt(ethTrades[0].signal_price).toLocaleString()}` : 'NA'}
        />
        <Dial color="#FF940D"
          asset="XLM"
          value={stats.altCoinValue}
          gainloss={parseInt(stats.altCoinValue) - parseInt(stats.altCoinDeposit)}
          gainlossperc={calcGainLossPerc(stats.altCoinValue, stats.altCoinDeposit)}
          startingvalue={parseFloat(stats.altCoinDeposit).toLocaleString()}
          fees={parseFloat(altCoinFees).toFixed(2)}
          tradeinfo={altCoinTrades.length > 0 ? altCoinTrades[0] : 'NA'}
          lasttrade={altCoinTrades.length > 0 ? new Date(altCoinTrades[0].created_at).toString('M/d/yyyy hh:mm tt') : 'NA'}
          lastsignal={altCoinTrades.length > 0 && altCoinTrades[0].side ? `${altCoinTrades[0].side.toUpperCase()} @ $${parseFloat(altCoinTrades[0].signal_price).toFixed(2).toLocaleString()}` : 'NA'}
        />
      </div>

      <div className="tableContainer">
        
        <div className="row">
          <div className="colname">
            ACTION / PRODUCT
          </div>
          <div className="colname mobilehide">
            DATE
          </div>
          <div className="colname">
            VALUE
          </div>
          <div className="colname">
            FEES
          </div>
        </div>
        {trades.map((trade) => {
          return (<>
            {trade.status !== 'pending' && 
            <div key={trade.id} className="row">
              <div className="tradebox">
                <div className="assetAction">
                <span className={trade.side === 'buy' ? 'buyside' : 'sellside'}>
                  {trade.side} &nbsp; 
                </span>
                <span className="skum">{trade.product_id} </span>
              </div>
                <div className="datecol desktophide">
                  <span className="executed">
                    Executed: {new Date(trade.done_at).toString('M/d/yyyy hh:mm tt')}
                  </span>
                  <span className="created">
                    Created: {new Date(trade.created_at).toString('M/d/yyyy hh:mm tt')}
                  </span>
                </div>
              </div>

              <div className="datecol mobilehide">
                <span className="executed">
                  Executed: {new Date(trade.done_at).toString('M/d/yyyy hh:mm tt')}
                </span>
                <span className="created">
                  Created: {new Date(trade.created_at).toString('M/d/yyyy hh:mm tt')}
                </span>
              </div>
              <div className="price">
                ${trade.executed_value ? parseFloat(trade.executed_value).toFixed(2) : ''}
              </div>
              <div className="fees">
                ${parseFloat(trade.fill_fees).toFixed(2) || ''}
              </div>
            </div>
            }

            {trade.status === 'pending' &&
              <div key={trade.id} className="row">
                <div style={{ display: `flex` }}>
                  <span className={trade.side === 'buy' ? 'buyside' : 'sellside'}>
                    PENDING TRADE
                  </span>
                  <span className="skum">&nbsp; {trade.product_id} </span>
                </div>

                <div className="datecol">
                  <span className="created">
                    Created: {new Date(trade.created_at).toString('M/d/yyyy hh:mm tt')}
                  </span>
                </div>
                <span>
                  ORDER PENDING
                </span>
                <span>
                 ORDER PENDING
                </span>
              </div>
            }
          
          </>
          )
        })
        }
      </div>
    </>
  );
}

export default CurrentTrades;