import * as React from "react"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer  } from 'recharts'
const data = [
  {
    name: 'Page A',
    BTC: 4000,
    ETH: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    BTC: 3000,
    ETH: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    BTC: 2000,
    ETH: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    BTC: 2780,
    ETH: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    BTC: 1890,
    ETH: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    BTC: 2390,
    ETH: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    BTC: 3490,
    ETH: 4300,
    amt: 2100,
  },
];



function Chart(props) {
  return (
    <ResponsiveContainer>
      <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 50,
            left: 50,
            bottom: 5,
          }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis orientation="right" />
        <Tooltip contentStyle={{background: `rgba(0,0,0,0.8)`, border: `none`, borderRadius: `10px`}}/>
        <Legend />
        <Line type="monotone" dataKey="BTC" stroke="#0DFF15" strokeWidth={3} activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="ETH" stroke="#00FFE5" strokeWidth={3} activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="LTC" stroke="#FF00C8" strokeWidth={3} activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="XLM" stroke="#FF940D" strokeWidth={3} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
)};

export default Chart;
