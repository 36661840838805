import React from "react"
import Layout from "../components//Layout/layout"
import SEO from "../components/seo"
import CurrentTrades from "../components/currentTrades"
import SkullGuy from "../components/skull"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <SkullGuy style={{ position: `absolute`, zIndex: '-1', left: '0px', top: '0px' }} />
    <CurrentTrades style={{position: `relative`, zIndex: '50'}} />
  </Layout>
)

export default IndexPage
