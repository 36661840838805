import * as React from "react"

function HeaderBox(props) {
  return (
    <svg style={{minWidth: '100%'}} viewBox="0 0 980 328" {...props}>
      <defs>
        <path
          id="b"
          d="M92.5540771 10.5728149L0 144.70639 84.3040771 159.313019 719.990477 171.325531 840.554077 85.6627655 798.155396 10.5728149 432.613677 0z"
        />
        <filter
          x="-16.7%"
          y="-58.4%"
          width="133.3%"
          height="263.4%"
          filterUnits="objectBoundingBox"
          id="abb"
        >
          <feOffset dy={40} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={40}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-4.2%"
          y="-112.7%"
          width="108.3%"
          height="325.4%"
          filterUnits="objectBoundingBox"
          id="cbb"
        >
          <feGaussianBlur stdDeviation={10} in="SourceGraphic" />
        </filter>
        <filter
          x="-33.6%"
          y="-252.5%"
          width="167.2%"
          height="604.9%"
          filterUnits="objectBoundingBox"
          id="dbb"
        >
          <feGaussianBlur stdDeviation={10} in="SourceGraphic" />
        </filter>
        <filter
          x="-26.0%"
          y="-125.4%"
          width="151.9%"
          height="350.7%"
          filterUnits="objectBoundingBox"
          id="ebb"
        >
          <feGaussianBlur stdDeviation={10} in="SourceGraphic" />
        </filter>
        <filter
          x="-19.4%"
          y="-178.2%"
          width="138.7%"
          height="456.4%"
          filterUnits="objectBoundingBox"
          id="fbb"
        >
          <feGaussianBlur stdDeviation={10} in="SourceGraphic" />
        </filter>
        <path
          id="hbb"
          d="M1.70530257e-13 20.2644043L84.3040771 34.8710327 719.990477 46.8835449z"
        />
        <filter
          x="-19.4%"
          y="-375.7%"
          width="138.9%"
          height="1151.9%"
          filterUnits="objectBoundingBox"
          id="gbb"
        >
          <feOffset dy={40} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={40}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-60.0%"
          y="-40.0%"
          width="220.0%"
          height="180.0%"
          filterUnits="objectBoundingBox"
          id="ibb"
        >
          <feGaussianBlur stdDeviation={6} in="SourceGraphic" />
        </filter>
        <filter
          x="-60.0%"
          y="-40.0%"
          width="220.0%"
          height="180.0%"
          filterUnits="objectBoundingBox"
          id="jbb"
        >
          <feGaussianBlur stdDeviation={6} in="SourceGraphic" />
        </filter>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g transform="translate(68 38) translate(.696 .558)">
          <use fill="#000" filter="url(#abb)" xlinkHref="#b" />
          <use fill="#141617" xlinkHref="#b" />
        </g>
        <g transform="translate(68 38) translate(.696 .558) translate(0 124.442)">
          <path
            fill="#00FFE5"
            filter="url(#cbb)"
            d="M1.70530257e-13 20.2644043L84.3040771 34.8710327 719.990477 46.8835449z"
          />
          <path
            fill="#FF00C8"
            filter="url(#dbb)"
            d="M7.63345337 10.6912994L90.3073082 22.5742569 1 19.2644043z"
          />
          <path
            fill="#0BFC13"
            filter="url(#ebb)"
            d="M15.5110779 -2.66453526e-14L8.30407715 9.89341736 94.1148987 22.5742569 123.812263 23.9325027z"
          />
          <path
            fill="#FF940D"
            filter="url(#fbb)"
            d="M67.6558876 10.6912994L127.66098 24.1669922 222.689354 27.5269737z"
          />
        </g>
        <g transform="translate(68 38) translate(.696 .558) translate(0 124.442)">
          <use fill="#000" filter="url(#gbb)" xlinkHref="#hbb" />
          <use fill="#00FFE5" xlinkHref="#hbb" />
          <path
            fill="#FF00C8"
            d="M7.63345337 10.6912994L90.3073082 22.5742569 1 19.2644043z"
          />
          <path
            fill="#0BFC13"
            d="M15.5110779 -2.66453526e-14L8.30407715 9.89341736 94.1148987 22.5742569 123.812263 23.9325027z"
          />
          <path
            fill="#FF940D"
            d="M67.6558876 10.6912994L127.66098 24.1669922 222.689354 27.5269737z"
          />
        </g>
        <text
          fontFamily="Skumfuk"
          fontSize={50}
          fontWeight="normal"
          fill="#444444"
          transform="translate(68 38)"
        >
          <tspan x={70} y={99}>
            {"KNOTTBOT"}
          </tspan>
        </text>
        <text
          fontFamily="ArialMT, Arial"
          fontSize={14}
          fontWeight="normal"
          fill="#E7E8E8"
          transform="translate(68 38)"
        >
          <tspan x={379} y={70}>
            {`Starting Value: $${props.deposit}`}
          </tspan>
        </text>
        <text
          fontFamily="Arial-BoldMT, Arial"
          fontSize={25}
          fontWeight="bold"
          fill="#E7E8E8"
          transform="translate(68 38)"
        >
          <tspan x={379} y={97}>
            {`Total Value: $${props.value}`}
          </tspan>
        </text>
        <text
          fontFamily="Skumfuk"
          fontSize={28}
          fontWeight="normal"
          fill={props.gainlossperc > 0 ? "#0DFF15" : "#D90000" }
          transform="translate(68 38)"
        >
          <tspan x={735.967758} y={90}>
            {props.gainlossperc}%
          </tspan>
        </text>
        <g className={props.gainlossperc > 0 ? '' : 'hide'} 
          transform="translate(68 38) translate(684 54)" fill="#0DFF15">
          <path
            filter="url(#ibb)"
            d="M0 21.142223L18.0357773 0 30 21.142223 20.2970864 16.1600049 18.0357773 45 14.9877402 15.8880934z"
          />
          <path d="M0 21.142223L18.0357773 0 30 21.142223 20.2970864 16.1600049 18.0357773 45 14.9877402 15.8880934z" />
        </g>
        <g
          className={props.gainlossperc < 0 ? '' : 'hide'}
          transform="translate(68 38) translate(684 57) matrix(1 0 0 -1 0 45)"
          fill="#D90000"
        >
          <path
            filter="url(#jbb)"
            d="M0 21.142223L18.0357773 0 30 21.142223 20.2970864 16.1600049 18.0357773 45 14.9877402 15.8880934z"
          />
          <path d="M0 21.142223L18.0357773 0 30 21.142223 20.2970864 16.1600049 18.0357773 45 14.9877402 15.8880934z" />
        </g>
      </g>
    </svg>
  )
}

export default HeaderBox
